"use strict";

(function ($) {
  $(document).ready(function () {
    var activeSelectors = {};
    $(".tag-selector-radio").click(function () {
      var Card = $(".diy-card");
      var parentData = $(this).data().parent;
      var targetData = $(this).data().target;
      var activeAttributes = [];
      var dataTargetAttribute = ".diy-card[data-attribute*=\"".concat(targetData, "\"]");
      var prevTarget = activeSelectors[parentData];
      activeSelectors[parentData] = targetData;

      if (Object.keys(activeSelectors).length === 0) {
        activeAttributes.push(dataTargetAttribute);
      } else {
        Object.keys(activeSelectors).forEach(function (key) {
          activeAttributes.push("[data-attribute*=\"".concat(activeSelectors[key], "\"]"));
        });
      }

      activeAttributes = activeAttributes.join("");
      Card.filter(activeAttributes).parent().css("display", "flex");
      Card.not(activeAttributes).parent().hide(0, function () {
        var cardHidden = $(".diy-card:hidden");

        if (cardHidden.length === Card.length) {
          $("#is-empty-notification").show();
        } else {
          $("#is-empty-notification").hide();
        }
      });
    });
  });
})(jQuery);
