/**
 * Mobile menu fix. Allows menu to be 100% height of screen.
 *
 * @author Rene Korss <rene.korss@redwall.ee>
 */

(function($){
	$(document).ready(function(){
		// Mobile menu
		$menu = $('.t3-navbar-collapse');
		$body = $('html, body');

		/* BS events for menu */

		// Menu hidden
		$menu.on('hide.bs.collapse', function(){
			showBody();
			$menu.css('height', 'auto');
		});

		// Menu shown
		$menu.on('shown.bs.collapse', function(){
			hideBody();
			$menu.animate({'height': getMaxMenuHeight()+'px'}, 100);
		});

		/**
		 * Show body
		 */

		showBody = function(){
			$body.css({'overflow': 'visible', 'position': 'relative'});
		}

		/**
		 * Hide body
		 */

		hideBody = function(){
			$body.css({'overflow': 'hidden', 'position': 'absolute'});
		}

		/**
		 * Function to get max menu height
		 */

		getMaxMenuHeight = function(){
			var winH = $(window).height();
			var maxH = winH - parseInt($menu.css('marginTop'));

			return maxH;
		}

		/* Edit menu height */
		calculateMenuHeight = function(){
			// Works only on mobile
			if($(window).width() < 992){
				var menuH = getMaxMenuHeight();
				$menu.css({'max-height': menuH+'px'});

				if($menu.is(':visible')){
					$menu.css({'height': menuH+'px'});
				}
			}else{
				showBody();
			}
		}

		// Call on init
		calculateMenuHeight();

		// Call on window resize
		$(window).on('resize', function(){
			calculateMenuHeight();
		});
	});
})(jQuery);
