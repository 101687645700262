"use strict";

(function ($) {
  function adjustLayout() {
    if ($(".article-card:visible").length <= 6) {
      $(".world-block").addClass("compact-view");
    } else {
      $(".world-block").removeClass("compact-view");
    }
  }

  $(document).ready(function () {
    var activeSelectors = [];
    var exerciseSelector = [];
    $(".tag-selector").click(function () {
      if ($(this).attr("checked") === "checked") {
        activeSelectors.push($(this).data("target"));
        $(".article-card")
          .not('[data-attribute~="'.concat($(this).data("target"), '"]'))
          .animate(
            {
              opacity: 0,
            },
            200,
            function () {
              $(this).css({
                display: "none",
              });

              var cardHidden = $(".article-card:hidden");
              var Card = $(".article-card");

              if (cardHidden.length === Card.length) {
                $("#is-empty-notification").show();
              } else {
                $("#is-empty-notification").hide();
              }

              adjustLayout();
            }
          );
        var concatedSelector = [".first-exercise-block"];

        for (var i = 0; i < activeSelectors.length; i++) {
          concatedSelector.push(
            '.world-exercise-block[data-attribute*="'.concat(
              activeSelectors[i],
              '"]'
            )
          );
        }

        exerciseSelector = concatedSelector;
        $(".world-exercise-block")
          .not(concatedSelector.join(", "))
          .animate(
            {
              opacity: 0,
            },
            200,
            function () {
              $(this).css({
                display: "none",
              });
            }
          );
        $(".world-exercise-block")
          .filter(concatedSelector.join(", "))
          .css({
            display: "block",
            opacity: 0,
          })
          .animate(
            {
              opacity: 1,
            },
            200,
            adjustLayout()
          );
      } else {
        var index = activeSelectors.indexOf($(this).data("target"));

        if (index > -1) {
          activeSelectors.splice(index, 1);
        }

        if (activeSelectors.length > 0) {
          var concatedSelector = ".article-card";

          for (var i = 0; i < activeSelectors.length; i++) {
            concatedSelector = concatedSelector.concat(
              '[data-attribute*="'.concat(activeSelectors[i], '"]')
            );
          }

          $(concatedSelector)
            .css({
              display: "block",
              opacity: 0,
            })
            .animate(
              {
                opacity: 1,
              },
              200,
              function () {
                var cardHidden = $(".article-card:hidden");
                var Card = $(".article-card");

                if (cardHidden.length === Card.length) {
                  $("#is-empty-notification").show();
                } else {
                  $("#is-empty-notification").hide();
                }
                adjustLayout();
              }
            );
          var excIndex = exerciseSelector.indexOf(
            '[data-attribute*="'.concat($(this).data("target"), '"]')
          );

          if (exerciseSelector.indexOf(excIndex > -1)) {
            exerciseSelector.splice(excIndex, 1);
          }

          $(".world-exercise-block")
            .not(exerciseSelector.join(", "))
            .animate(
              {
                opacity: 0,
              },
              200,
              function () {
                $(this).css({
                  display: "none",
                });
              }
            );
        } else {
          $(".article-card, .world-exercise-block")
            .css({
              display: "block",
              opacity: 0,
            })
            .animate(
              {
                opacity: 1,
              },
              200,
              adjustLayout()
            );
        }
      }
    });
  });
})(jQuery);
