(function($) {
    jQuery(document).ready(function ($) {
        function setTitle(modal, title){
            if (title.lenth > 0) {
                modal.find('#modalLabel').text(title);
            } else {
                modal.find('#modalLabel').remove();
            }
        }

        var modalHTML = '<div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">' +
          '<div class="modal-dialog d-flex" role="document">' +
          '<button type="button" class="close material-icons material-btn btn-circle-xs" data-dismiss="modal" aria-label="Close">close</button>' +
            '<div class="modal-content">' +
              '<div class="modal-body">' +
              '</div>' +
            '</div>' +
          '</div>' +
        '</div>';

        $(document).on('click', '.modal-link', function(e){
            e.preventDefault();

            $.get($(this).attr('href'), function(data, statusText, status){
                if (status.status == 200 && statusText == 'success'){
                    var modal = $(modalHTML);
                    var title = $(this).attr('data-title') ? $(this).data('title') : '';

                    setTitle(modal, title);

                    var content = $(data).find('.item-content .article-content').html();

                    modal.find('.modal-body').append(content);
                    modal.find('iframe').attr('src', $(this).attr('href'));

                    modal.modal();
                }
            });
        });

        $(document).on('hidden.bs.modal', '#modal', function (e) {
            $(this).remove();
        });
    });
})(jQuery);
